import React, { FC, Suspense } from 'react';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Heading, Text } from '@wix/design-system';
import { useProfileContext } from '../../Contexts/ProfileContext';
import ShowMoreText from '../../ShowMoreText';
import { Platforms } from '@wix/ambassador-partner-profiles/types';
import WixLogo from '@components/Images/WixLogo';
import VerticalDivider from '@components/VerticalDivider';
import StudioLogoSvg from './StudioSvg';
import Certifications from '../Certifications';
import { SHOW_MARKETPLACE_PROFILE_CERTIFICATIONS } from '@utils/constants';

type Props = {
  isMobileView?: boolean;
};

const WorksOn = (
  text: string,
  platforms?: Platforms,
  isMobileView?: boolean,
) => {
  const { studio, wix } = platforms || {};
  return studio || wix ? (
    <Box
      gap="18px"
      paddingTop={isMobileView ? '48px' : '36px'}
      paddingBottom={isMobileView ? '24px' : '0'}
      direction="horizontal"
      verticalAlign="middle"
    >
      <Text weight="bold" size="medium">
        {text}
      </Text>
      <Box direction="horizontal" verticalAlign="middle" paddingTop="2px">
        {studio && <StudioLogoSvg />}
        {wix && studio && (
          <Box padding="0 5.5px" display="block">
            <VerticalDivider />
          </Box>
        )}
        {wix && <WixLogo />}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

const About: FC<Props> = ({ isMobileView }) => {
  const MAX_CHARS_IN_ABOUT = 200;
  const {
    partnerProfile: { professionalInformation, platforms },
  } = useProfileContext();
  const { t } = useTranslation();
  const about = professionalInformation?.about;
  const businessName = professionalInformation?.businessName;
  const { experiments } = useExperiments({ readOnly: true });
  const isShowingCertifications = experiments.enabled(
    SHOW_MARKETPLACE_PROFILE_CERTIFICATIONS,
  );

  return (
    <Box
      direction="vertical"
      paddingTop="36px"
      dataHook="about-section"
      maxWidth={isMobileView ? '80vw' : '40vw'}
      width="100%"
    >
      <Box direction="vertical">
        <Heading
          size={isMobileView ? 'medium' : 'large'}
          as="h1"
          dataHook="about-header"
        >
          {t('profile-page-about-section-title', { businessName })}
        </Heading>
        <Box minHeight="18px" />
        <ShowMoreText originalText={about} maxChars={MAX_CHARS_IN_ABOUT} />
        {isShowingCertifications && isMobileView && (
          <Suspense fallback="">
            <Certifications isMobileView />
          </Suspense>
        )}
        {WorksOn(
          t('profile-page-about-section-works-on-platform'),
          platforms,
          isMobileView,
        )}
      </Box>
    </Box>
  );
};

export default About;
