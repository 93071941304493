import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

const WebDesignerCertification = ({ height = 60, width = 60 }: Props) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="0 0 187 187"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 187"
      height={height}
      width={width}
      data-type="color"
      role="img"
      aria-label="certification logo"
    >
      <g>
        <path
          fill="#3A00E9"
          d="M93.5 187c51.639 0 93.5-41.861 93.5-93.5S145.139 0 93.5 0 0 41.861 0 93.5 41.861 187 93.5 187Z"
          data-color="1"
        ></path>
        <path
          fill="#3A00E9"
          d="M93.5 187c51.639 0 93.5-41.861 93.5-93.5S145.139 0 93.5 0 0 41.861 0 93.5 41.861 187 93.5 187Z"
          data-color="1"
        ></path>
        <path
          fill="#ffffff"
          d="M129.216 130.373H57.771a1.42 1.42 0 0 1-1.418-1.418V57.867c0-.78.635-1.418 1.418-1.418h71.089a1.42 1.42 0 0 1 1.417 1.418v71.445c0 .584-.476 1.061-1.061 1.061Zm-71.445-73.09a.588.588 0 0 0-.589.588v71.089a.59.59 0 0 0 .59.588h71.444a.23.23 0 0 0 .232-.232V57.871a.588.588 0 0 0-.588-.589H57.771Zm35.542 62.127c-14.333 0-25.996-11.664-25.996-25.997S78.98 67.417 93.313 67.417c14.333 0 25.997 11.663 25.997 25.996 0 14.333-11.664 25.997-25.997 25.997Zm0-51.164c-13.877 0-25.167 11.29-25.167 25.167 0 13.877 11.29 25.168 25.167 25.168 13.877 0 25.168-11.291 25.168-25.168 0-13.877-11.291-25.167-25.168-25.167Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M114.776 68.526H71.309a.416.416 0 0 1-.415-.414c0-.228.187-.415.415-.415h43.467c.228 0 .415.187.415.415a.416.416 0 0 1-.415.414Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M91.005 70.147h4.074v-4.075h-4.074v4.075Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M95.494 70.566h-4.903v-4.904h4.903v4.903Zm-4.074-.83h3.245v-3.245H91.42v3.246Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M67.338 68.11a2.037 2.037 0 1 0 4.074 0 2.037 2.037 0 0 0-4.074 0Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M69.377 70.566a2.453 2.453 0 1 1 0-4.908 2.453 2.453 0 0 1 0 4.908Zm0-4.075c-.896 0-1.625.73-1.625 1.625s.73 1.625 1.625 1.625S71 69.01 71 68.116s-.73-1.625-1.624-1.625Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M114.577 68.11a2.037 2.037 0 1 0 4.074 0 2.037 2.037 0 0 0-4.074 0Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M116.612 70.566a2.454 2.454 0 1 1 0-4.907 2.454 2.454 0 0 1 0 4.907Zm0-4.075c-.895 0-1.625.73-1.625 1.625s.73 1.625 1.625 1.625 1.625-.73 1.625-1.625-.73-1.625-1.625-1.625Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M86.269 111.129h13.479v-6.134h-13.48v6.134Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M90.74 73.125h.654c.328 0 .593.265.593.593V88.8a2.131 2.131 0 0 0-1.102 2.296 2.15 2.15 0 0 0 4.26-.414 2.13 2.13 0 0 0-1.14-1.882V73.718c0-.328.266-.593.594-.593h.654c.15 0 .295.054.406.157 4.232 3.946 8.162 13.79 8.162 18.503 0 4.315-2.529 8.659-6.18 10.744a.583.583 0 0 1-.299.078h-8.679a.583.583 0 0 1-.298-.078c-3.656-2.081-6.18-6.429-6.18-10.744 0-4.712 3.929-14.557 8.16-18.502a.58.58 0 0 1 .407-.158h-.012Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m17.855 121-.55-1.758 5.582-3.689-6.69.15-.547-1.745 7.722-5.099.54 1.72-5.916 3.66 7.018-.136.456 1.459-5.928 3.912 7.034-.377.523 1.675-9.248.219.005.009Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m21.627 129.523-.737-1.509 7.983-3.912.737 1.508-7.983 3.913Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m24.871 135.382-1.073-1.675 5.641.249 2.002-4.8 1.073 1.675-1.525 3.403 3.73.029 1.062 1.654-5.186-.158-2.13 5.223-1.073-1.675 1.64-3.817-4.16-.108Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m36.016 147.744 1.6-.033c-.05.518.028 1.044.231 1.579.204.535.51.999.916 1.388.436.419.863.668 1.273.751.41.078.746-.017 1.007-.282.228-.241.299-.543.212-.912-.092-.369-.386-.879-.892-1.538-.742-.966-1.16-1.782-1.264-2.454-.103-.671.087-1.26.572-1.761.373-.39.813-.626 1.318-.717.506-.087 1.028-.033 1.571.166.543.199 1.061.534 1.55 1.007.572.551.979 1.152 1.219 1.799.24.65.34 1.301.307 1.96l-1.584.033a3.392 3.392 0 0 0-.14-1.471 3.007 3.007 0 0 0-.796-1.256c-.39-.373-.78-.601-1.173-.68-.394-.078-.717.013-.97.274-.203.211-.249.477-.145.808.103.328.402.817.895 1.463.742.966 1.156 1.799 1.256 2.508.095.709-.12 1.335-.647 1.886-.39.406-.845.651-1.363.733-.519.083-1.057.017-1.613-.207-.56-.219-1.102-.584-1.625-1.09-.617-.593-1.06-1.231-1.33-1.915a5.215 5.215 0 0 1-.386-2.039Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m47.91 159.733-1.372-.966 4.286-6.093-2.28-1.604.83-1.181 5.93 4.169-.829 1.182-2.28-1.604-4.285 6.093v.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m65.248 159.291-2.379 4.829c-.518 1.048-1.223 1.716-2.114 2.002-.891.286-1.873.165-2.934-.357-1.057-.522-1.745-1.223-2.06-2.105-.32-.883-.22-1.849.298-2.898l2.38-4.828 1.504.742-2.38 4.828c-.298.61-.372 1.177-.21 1.696.157.522.534.928 1.13 1.222.602.299 1.161.348 1.675.162.514-.191.92-.589 1.223-1.202l2.38-4.829 1.495.738h-.008Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m66.47 169.302 2.583-8.509 2.976.904c.912.277 1.658.7 2.23 1.276a4.229 4.229 0 0 1 1.148 1.994c.19.754.162 1.546-.087 2.371-.253.833-.667 1.508-1.247 2.031a4.24 4.24 0 0 1-2.064 1.019c-.796.158-1.654.096-2.566-.182l-2.976-.904h.004Zm2.024-.882 1.367.414c.597.182 1.144.216 1.65.112a2.566 2.566 0 0 0 1.306-.709c.365-.365.638-.837.812-1.417.174-.581.212-1.12.108-1.625a2.59 2.59 0 0 0-.696-1.31c-.361-.369-.838-.642-1.435-.825l-1.367-.414-1.75 5.769.005.005Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m80.726 172.865-1.654-.286 1.505-8.763 1.654.286-1.505 8.763Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M90.602 174.035a5.025 5.025 0 0 1-1.882-.419 4.48 4.48 0 0 1-2.408-2.541 4.744 4.744 0 0 1-.286-1.865 4.73 4.73 0 0 1 .423-1.828 4.412 4.412 0 0 1 1.049-1.434 4.574 4.574 0 0 1 1.537-.92 5.004 5.004 0 0 1 1.907-.278 5.087 5.087 0 0 1 1.89.419 4.559 4.559 0 0 1 1.476 1.032c.41.435.721.937.932 1.509.212.572.307 1.189.282 1.852a4.83 4.83 0 0 1-.414 1.841 4.448 4.448 0 0 1-1.04 1.438 4.571 4.571 0 0 1-1.547.92 5.079 5.079 0 0 1-1.915.278l-.004-.004Zm.054-1.493c.589.021 1.111-.095 1.567-.348a2.84 2.84 0 0 0 1.09-1.077c.27-.469.415-1.008.44-1.621.02-.613-.084-1.161-.32-1.641a2.862 2.862 0 0 0-1.007-1.153c-.435-.286-.95-.439-1.538-.46-.588-.02-1.11.096-1.567.348-.455.253-.82.61-1.09 1.074-.269.464-.414 1.003-.439 1.612-.025.61.083 1.161.32 1.65.235.485.571.87 1.006 1.156.436.286.95.44 1.538.46Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m112.766 168.959 1.314.867a4.204 4.204 0 0 1-1.244 1.504 4.737 4.737 0 0 1-1.948.879 5.034 5.034 0 0 1-1.923.054 4.503 4.503 0 0 1-1.654-.638 4.275 4.275 0 0 1-1.256-1.24c-.34-.51-.58-1.09-.721-1.749a4.834 4.834 0 0 1-.045-1.886 4.239 4.239 0 0 1 .663-1.641 4.582 4.582 0 0 1 1.268-1.26 4.973 4.973 0 0 1 1.77-.734c.746-.157 1.451-.153 2.118.013.667.169 1.243.46 1.728.87l-.849 1.326a3.115 3.115 0 0 0-1.231-.704 2.884 2.884 0 0 0-1.459-.046 2.809 2.809 0 0 0-1.393.717 2.933 2.933 0 0 0-.796 1.314c-.153.514-.166 1.069-.041 1.662.124.593.364 1.094.713 1.509.348.414.771.709 1.26.883a2.838 2.838 0 0 0 1.562.087 2.89 2.89 0 0 0 1.314-.634c.365-.315.643-.697.837-1.144l.013-.009Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m125.116 167.488-5.844 2.226-3.163-8.31 5.845-2.226.505 1.326-4.277 1.629.804 2.114 4.054-1.546.505 1.326-4.053 1.546.845 2.222 4.278-1.629.505 1.326-.004-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m131.305 164.467-1.463.824-4.368-7.742 3.141-1.77c.556-.315 1.099-.489 1.625-.522a2.758 2.758 0 0 1 1.443.277c.435.22.783.568 1.048 1.037.307.543.415 1.094.328 1.657-.087.564-.348 1.074-.788 1.53l4.286 1.753-1.786 1.007-3.984-1.724-1.181.667 1.699 3.01v-.004Zm-1.989-7.457-1.679.945 1.277 2.263 1.679-.949c.402-.228.663-.51.783-.842.116-.331.075-.675-.124-1.027-.195-.349-.465-.556-.813-.626-.348-.071-.721.004-1.123.232v.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m143.206 156.681-1.33 1.024-4.539-5.906-2.209 1.695-.879-1.144 5.749-4.418.879 1.144-2.21 1.695 4.539 5.906v.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m150.211 150.498-1.202 1.169-6.197-6.375 1.203-1.169 6.196 6.375Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m154.964 145.343-1.065 1.298-6.873-5.641 3.81-4.643 1.094.9-2.744 3.345 1.749 1.434 2.595-3.163 1.094.9-2.595 3.162 2.931 2.404.004.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m161.427 136.526-.912 1.41-7.465-4.833.912-1.41 7.465 4.833Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m166.955 126.331-2.777 5.604-7.966-3.95 2.777-5.604 1.268.63-2.031 4.099 2.027 1.007 1.928-3.888 1.268.63-1.928 3.888 2.127 1.053 2.031-4.099 1.268.63h.008Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m168.957 121.481-8.477-2.686.941-2.964c.286-.908.722-1.645 1.302-2.213a4.198 4.198 0 0 1 2.006-1.123c.758-.183 1.546-.141 2.371.12.829.261 1.5.688 2.014 1.272.514.585.846 1.277.991 2.077.145.8.074 1.654-.212 2.561l-.94 2.964.004-.008Zm-.854-2.031.431-1.364c.186-.593.232-1.14.133-1.65a2.555 2.555 0 0 0-.693-1.314c-.36-.373-.829-.646-1.405-.833-.576-.182-1.119-.224-1.62-.128a2.55 2.55 0 0 0-1.318.68c-.373.356-.655.829-.842 1.421l-.431 1.364 5.749 1.824h-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m41.777 52.048-1.986 3.026-9.069-2.79 6.164 7.213-1.986 3.025-14.043-4.53 2.01-3.059 9.447 3.56-6.648-7.817 1.595-2.429 9.915 3.06-7.096-7.35 1.964-2.992 9.74 11.083h-.007Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m52.897 41.231-7.664 7.01-9.6-10.5 7.665-7.009 1.919 2.102-5.14 4.7 1.894 2.068 4.862-4.443 1.92 2.102-4.863 4.443 1.949 2.13 5.14-4.696 1.918 2.102v-.009Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m54.26 40.185-7.445-12.12 5.356-3.29c.812-.502 1.604-.809 2.375-.925.77-.116 1.467-.042 2.097.22.626.26 1.136.708 1.525 1.338.344.564.502 1.157.473 1.779-.03.621-.224 1.222-.585 1.803.838-.158 1.604-.108 2.297.145.692.252 1.243.713 1.653 1.384.44.713.63 1.455.572 2.226-.053.775-.331 1.517-.837 2.23-.501.713-1.214 1.351-2.126 1.915l-5.355 3.29v.005Zm-.825-12.895-2.238 1.376 1.459 2.375 2.238-1.376c.418-.257.675-.568.77-.928.096-.365.026-.738-.21-1.12-.224-.36-.51-.571-.863-.625-.352-.054-.738.045-1.156.302v-.004Zm2.271 8.717 2.458-1.509c.497-.307.817-.667.953-1.086.141-.418.08-.845-.186-1.276-.245-.394-.576-.618-1.003-.668-.427-.05-.891.079-1.385.386l-2.457 1.508 1.624 2.645h-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M69.025 31.901 65.053 18.24l5.007-1.455c1.517-.44 2.922-.535 4.215-.282 1.293.253 2.404.8 3.328 1.642.92.845 1.58 1.935 1.965 3.27.39 1.339.419 2.611.095 3.817-.327 1.206-.965 2.268-1.923 3.18-.957.911-2.192 1.591-3.71 2.03l-5.007 1.455v.004Zm2.49-3.689 1.72-.501c1.26-.365 2.148-1.024 2.658-1.973.51-.95.584-2.056.215-3.316-.365-1.248-1.015-2.135-1.956-2.665-.941-.53-2.04-.61-3.304-.24l-1.72.501 2.383 8.194h.005Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m93.6 27.297-10.354.825-1.128-14.18 10.354-.825.224 2.835-6.943.551.224 2.798 6.566-.522.223 2.835-6.565.522.228 2.877 6.942-.551.224 2.835h.005Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m94.86 24.867 2.495-1.99c.257.444.605.846 1.052 1.206.444.361.937.651 1.472.875a6.085 6.085 0 0 0 1.612.431c.858.1 1.521.05 1.99-.15.468-.198.729-.5.779-.911.046-.394-.133-.754-.535-1.074-.402-.319-1.185-.638-2.354-.945-1.878-.489-3.22-1.144-4.025-1.964-.804-.821-1.14-1.812-1.003-2.972.104-.883.444-1.637 1.028-2.263.58-.626 1.33-1.082 2.255-1.368.924-.286 1.944-.365 3.063-.232 1.331.157 2.483.539 3.461 1.148.978.61 1.762 1.343 2.354 2.2l-2.478 1.995a4.802 4.802 0 0 0-1.559-1.667c-.663-.439-1.355-.7-2.081-.783-.712-.083-1.305-.02-1.786.19-.481.212-.742.527-.792.95a.82.82 0 0 0 .195.642c.158.19.452.377.887.556.435.178 1.036.377 1.811.6 1.812.506 3.138 1.182 3.98 2.032.841.845 1.189 1.894 1.044 3.146-.108.92-.464 1.687-1.069 2.296-.605.61-1.389 1.044-2.346 1.297-.958.253-2.035.311-3.233.17-1.422-.166-2.661-.564-3.718-1.185-1.057-.626-1.886-1.368-2.495-2.226l-.005-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m112.284 30.24-3.304-.886 3.685-13.74 3.304.886-3.685 13.74Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m127.248 36.347-2.864-1.294.572-1.268c-1.306.663-2.749.638-4.332-.079a7.654 7.654 0 0 1-2.383-1.658 6.763 6.763 0 0 1-1.48-2.33 6.99 6.99 0 0 1-.427-2.78c.046-.987.294-1.986.755-3.001.443-.978 1.023-1.8 1.745-2.462a6.95 6.95 0 0 1 2.445-1.472 7.47 7.47 0 0 1 2.897-.393c1.024.053 2.048.315 3.076.779 1.16.522 2.122 1.227 2.889 2.114.762.883 1.281 1.815 1.554 2.797l-2.918 1.149a4.632 4.632 0 0 0-.962-1.953 4.882 4.882 0 0 0-1.794-1.367c-.738-.332-1.476-.46-2.209-.378a3.969 3.969 0 0 0-2.002.809c-.601.456-1.078 1.077-1.43 1.865-.365.808-.527 1.596-.477 2.362.05.763.282 1.451.705 2.065.422.613 1.007 1.09 1.757 1.425a4.798 4.798 0 0 0 1.956.448c.655 0 1.252-.178 1.795-.543l.452-.999-3.299-1.492 1.106-2.45 6.184 2.794-3.299 7.307-.012.005Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m131.327 39.347-2.661-2.052 8.684-11.266 2.818 2.176-.734 10.665 5.43-7.042 2.661 2.052-8.683 11.265-2.819-2.176.734-10.664-5.43 7.042Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m146.829 54.319-6.644-7.697 10.54-9.102 6.648 7.697-2.109 1.82-4.456-5.161-2.081 1.794 4.215 4.879-2.109 1.82-4.216-4.879-2.138 1.845 4.455 5.16-2.109 1.82.004.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="m149.501 58.547-1.666-2.906 12.082-6.93 2.898 5.053c.518.899.808 1.79.874 2.665.066.874-.079 1.67-.439 2.387-.361.717-.929 1.297-1.708 1.741-.787.452-1.6.642-2.433.576-.833-.07-1.612-.369-2.334-.895l-2.694 6.304-2.014-3.515 2.537-5.562-.796-1.385-4.311 2.47.004-.003Zm10.897-3.4-1.231-2.146-2.939 1.687 1.231 2.147c.286.497.643.812 1.074.953.431.141.874.083 1.326-.174.452-.257.726-.61.821-1.053.095-.443 0-.916-.282-1.409v-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M19.12 101.81a.41.41 0 0 1-.41-.373 75.965 75.965 0 0 1-.386-7.651 75.16 75.16 0 0 1 4.265-25.04.416.416 0 0 1 .783.278 74.403 74.403 0 0 0-4.215 24.766c0 2.52.129 5.065.382 7.568a.417.417 0 0 1-.37.456h-.04l-.009-.004Z"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M168.323 101.811h-.041a.414.414 0 0 1-.369-.456c.253-2.504.381-5.049.381-7.569a74.398 74.398 0 0 0-4.215-24.766.418.418 0 0 1 .253-.53.419.419 0 0 1 .53.253 75.177 75.177 0 0 1 4.265 25.039 75.95 75.95 0 0 1-.385 7.651.416.416 0 0 1-.411.373l-.008.005Z"
          data-color="2"
        ></path>
      </g>
    </svg>
  );
};

export default WebDesignerCertification;
