import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

const AccessibilityCertification = ({ height = 60, width = 60 }: Props) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="0 0 406.801 406.801"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 406.801 406.801"
      height={height}
      width={width}
      data-type="color"
      role="img"
      aria-label="certification logo"
    >
      <g>
        <path
          fill="#CDC4FF"
          d="M203.4 406.801c112.335 0 203.401-91.065 203.401-203.401C406.801 91.065 315.735 0 203.4 0 91.065 0 0 91.066 0 203.4c0 112.336 91.066 203.401 203.4 203.401Z"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="m90.022 113.283-4.232 6.806-5.852-1.241-6.648 10.698 3.71 4.702-4.09 6.583-19.919-26.581 4.363-7.027 32.655 6.073.013-.013Zm-20.769 11.051 4.246-6.845-11.534-2.429 7.288 9.261v.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m99.922 88.99 6.832-.04c.261 2.182 0 4.442-.771 6.793-.784 2.351-2.103 4.493-3.958 6.426-2.299 2.391-4.82 4.01-7.575 4.872-2.743.862-5.487.954-8.23.288-2.742-.666-5.237-2.103-7.51-4.285-1.685-1.619-2.952-3.422-3.788-5.394a15.273 15.273 0 0 1-1.254-6.1c0-2.09.444-4.154 1.306-6.191.862-2.038 2.142-3.945 3.866-5.735 1.855-1.933 3.932-3.317 6.205-4.14 2.286-.836 4.52-1.15 6.7-.967l.223 6.819a9.636 9.636 0 0 0-4.52.365 9.343 9.343 0 0 0-3.905 2.469c-1.202 1.24-1.986 2.651-2.338 4.206a8.77 8.77 0 0 0 .209 4.676c.496 1.567 1.423 2.991 2.769 4.297 1.358 1.307 2.821 2.182 4.415 2.626 1.593.444 3.16.47 4.702.052 1.541-.418 2.913-1.254 4.115-2.495a9.193 9.193 0 0 0 2.298-4.01c.405-1.502.484-3.017.21-4.52v-.012Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m125.905 69.958 6.688 1.385c-.196 2.181-.914 4.35-2.168 6.492-1.254 2.142-2.991 3.957-5.212 5.46-2.743 1.854-5.551 2.925-8.425 3.2-2.873.274-5.564-.21-8.111-1.437-2.534-1.228-4.689-3.135-6.453-5.747-1.306-1.934-2.168-3.958-2.586-6.061a15.166 15.166 0 0 1 .04-6.23c.443-2.038 1.292-3.971 2.559-5.787 1.267-1.816 2.926-3.422 4.977-4.807 2.22-1.502 4.532-2.43 6.936-2.77 2.403-.339 4.65-.195 6.753.445l-1.202 6.714a9.756 9.756 0 0 0-4.493-.588c-1.555.157-2.991.692-4.324 1.594-1.437.966-2.482 2.181-3.161 3.63-.679 1.45-.927 2.992-.77 4.625.169 1.633.77 3.226 1.828 4.78 1.045 1.555 2.312 2.717 3.775 3.488 1.463.77 2.991 1.11 4.585 1.019 1.594-.092 3.109-.614 4.546-1.594 1.332-.901 2.364-2.05 3.082-3.448a9.942 9.942 0 0 0 1.136-4.376v.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m159.12 65.807-20.86 8.686-11.886-28.567 20.86-8.686 2.377 5.708-13.989 5.826 2.338 5.63 13.232-5.5 2.377 5.709-13.232 5.499 2.417 5.8 13.989-5.826 2.377 5.708v.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m160.101 59.92 3.814-5.8c.823.745 1.829 1.346 2.991 1.777a12.74 12.74 0 0 0 3.631.81c1.254.091 2.469.026 3.618-.196 1.842-.366 3.174-.915 4.023-1.646.836-.732 1.176-1.529.993-2.404-.17-.849-.784-1.462-1.828-1.854-1.045-.392-2.887-.51-5.5-.366-4.219.261-7.432-.183-9.652-1.346-2.221-1.149-3.579-2.978-4.089-5.473-.378-1.893-.183-3.683.588-5.368.784-1.685 2.025-3.135 3.736-4.35 1.711-1.214 3.775-2.063 6.178-2.547 2.861-.574 5.499-.561 7.929.026 2.429.601 4.545 1.581 6.361 2.953l-3.775 5.799c-1.228-1.136-2.678-1.933-4.35-2.39-1.672-.458-3.278-.536-4.833-.222-1.528.313-2.717.849-3.566 1.606-.849.758-1.175 1.594-.992 2.495.104.51.379.914.836 1.202.457.287 1.188.47 2.207.548 1.019.079 2.404.079 4.154.026 4.088-.182 7.288.327 9.6 1.503 2.312 1.188 3.749 3.121 4.285 5.8.405 1.984.183 3.8-.666 5.472-.836 1.672-2.169 3.096-3.971 4.271-1.816 1.176-3.997 2.025-6.57 2.547-3.057.614-5.878.64-8.491.053-2.612-.588-4.82-1.555-6.661-2.913v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m189.487 54.131 4.912-4.924c.666.901 1.515 1.685 2.573 2.35a13.065 13.065 0 0 0 3.396 1.53c1.215.352 2.403.522 3.592.535 1.881 0 3.292-.261 4.271-.81.967-.549 1.45-1.267 1.463-2.155 0-.862-.47-1.594-1.424-2.195-.953-.588-2.716-1.084-5.316-1.463-4.18-.588-7.249-1.672-9.182-3.252-1.947-1.58-2.913-3.631-2.9-6.178 0-1.934.562-3.645 1.659-5.147 1.097-1.502 2.612-2.664 4.532-3.513 1.92-.85 4.115-1.267 6.557-1.254 2.913 0 5.499.548 7.759 1.62 2.26 1.07 4.141 2.468 5.63 4.166l-4.859 4.924a10.373 10.373 0 0 0-3.775-3.213c-1.541-.784-3.109-1.175-4.689-1.188-1.555 0-2.835.274-3.814.849-.98.574-1.476 1.319-1.476 2.246 0 .523.195.967.574 1.346.392.378 1.071.705 2.051.98.98.273 2.338.56 4.062.861 4.036.653 7.067 1.777 9.104 3.41 2.025 1.62 3.044 3.8 3.031 6.544 0 2.024-.588 3.761-1.75 5.237-1.163 1.476-2.744 2.6-4.755 3.383-2.012.784-4.323 1.176-6.949 1.163-3.122 0-5.891-.562-8.32-1.646-2.43-1.084-4.415-2.482-5.944-4.193l-.013-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m228.466 61.468-7.354-1.123 4.65-30.591 7.367 1.123-4.65 30.591h-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m233.26 61.952 8.908-29.637 13.102 3.931c1.985.6 3.631 1.437 4.937 2.521 1.306 1.084 2.194 2.325 2.678 3.723.483 1.397.496 2.873.026 4.415-.418 1.371-1.15 2.495-2.208 3.343-1.058.85-2.312 1.411-3.775 1.66 1.463 1.136 2.495 2.455 3.083 3.944.588 1.49.64 3.057.144 4.69-.523 1.736-1.45 3.121-2.782 4.153-1.333 1.032-2.953 1.659-4.833 1.868-1.894.222-3.945 0-6.192-.68l-13.101-3.931h.013Zm8.882-3.71 6.009 1.803c1.215.366 2.26.353 3.148-.026.888-.379 1.476-1.097 1.802-2.142.288-.967.17-1.829-.365-2.6-.536-.77-1.411-1.332-2.639-1.698l-6.008-1.802-1.947 6.465Zm10.868-16.3-5.473-1.647-1.737 5.8 5.473 1.646c1.018.3 1.894.274 2.625-.092.732-.366 1.228-1.019 1.515-1.96.262-.887.183-1.658-.235-2.311s-1.149-1.136-2.168-1.437Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m267.313 74.248-6.753-3.135 13.022-28.07 6.753 3.135-13.022 28.07Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m289.753 87.608-17.999-11.39 16.549-26.15 6.296 3.984-13.14 20.769 11.703 7.406-3.396 5.381h-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m298.009 94.817-5.721-4.767 19.789-23.786 5.721 4.767-19.802 23.786h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m312.167 109.108-5.003-5.512 18.287-16.562-6.244-6.897 4.65-4.219 17.503 19.305-4.65 4.22-6.243-6.897-18.287 16.562h-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m328.977 133.271-3.775-6.413 10.189-5.996 10.371-20.102 4.48 7.615-6.936 12.213 14.055-.079 4.454 7.576-22.689-.77-10.149 5.969v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m38.232 260.977-1.15-3.828 12.227-7.902-14.551.183-1.15-3.801 16.916-10.92 1.136 3.762-12.957 7.837 15.269-.144.967 3.187-12.984 8.386 15.309-.666 1.097 3.657-20.115.275-.014-.026Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m46.397 279.921-1.58-3.292 17.437-8.359 1.58 3.291-17.437 8.36Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m53.502 293.033-2.338-3.644 12.278.535 4.35-10.449 2.338 3.644-3.318 7.406 8.112.065 2.312 3.592-11.286-.339-4.624 11.364-2.338-3.645 3.566-8.307-9.052-.222Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m78.084 320.567 3.474-.066c-.104 1.124.052 2.273.496 3.436a8.263 8.263 0 0 0 1.986 3.03c.94.914 1.867 1.463 2.769 1.633.901.169 1.633-.026 2.194-.614.497-.523.653-1.176.457-1.973-.195-.796-.835-1.92-1.933-3.357-1.606-2.102-2.52-3.879-2.73-5.342-.222-1.463.196-2.743 1.254-3.827a5.135 5.135 0 0 1 2.874-1.554c1.097-.196 2.246-.066 3.422.365 1.176.444 2.3 1.176 3.37 2.195 1.241 1.201 2.13 2.508 2.639 3.918.51 1.411.731 2.835.653 4.259l-3.449.065a7.35 7.35 0 0 0-.3-3.2 6.39 6.39 0 0 0-1.724-2.73c-.85-.81-1.698-1.306-2.547-1.489-.862-.17-1.568.026-2.116.587-.444.458-.549 1.045-.327 1.751.222.718.875 1.776 1.946 3.187 1.607 2.103 2.508 3.918 2.717 5.46.21 1.541-.261 2.9-1.424 4.101-.849.888-1.841 1.411-2.965 1.594-1.123.183-2.298.026-3.513-.457-1.215-.484-2.39-1.28-3.54-2.378-1.332-1.293-2.299-2.677-2.887-4.179-.587-1.503-.862-2.979-.823-4.442l.026.027Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m104.206 346.926-2.991-2.103 9.326-13.258-4.95-3.487 1.802-2.56 12.892 9.078-1.802 2.56-4.951-3.488-9.326 13.258Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m142.218 346.078-5.16 10.515c-1.123 2.286-2.651 3.736-4.598 4.363-1.946.627-4.075.366-6.387-.771-2.299-1.123-3.801-2.651-4.493-4.572-.693-1.92-.484-4.023.64-6.295l5.159-10.515 3.279 1.606-5.16 10.515c-.653 1.333-.796 2.56-.457 3.697.353 1.136 1.163 2.024 2.469 2.651 1.306.64 2.521.758 3.644.34 1.124-.418 1.999-1.293 2.652-2.612l5.159-10.515 3.253 1.593Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m145.222 367.953 5.551-18.535 6.479 1.933c1.985.601 3.605 1.515 4.859 2.756 1.254 1.241 2.09 2.678 2.521 4.324.418 1.646.365 3.357-.17 5.159-.549 1.816-1.437 3.292-2.691 4.428-1.254 1.137-2.743 1.881-4.48 2.234-1.737.352-3.592.222-5.578-.366l-6.478-1.933h-.013Zm4.388-1.933 2.978.888c1.294.392 2.495.457 3.593.222a5.593 5.593 0 0 0 2.834-1.554c.797-.797 1.385-1.829 1.75-3.083.379-1.267.457-2.443.222-3.527-.222-1.097-.731-2.037-1.515-2.847-.784-.797-1.829-1.398-3.122-1.777l-2.978-.888-3.762 12.566Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m176.612 375.647-3.605-.601 3.161-19.083 3.605.601-3.161 19.083Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M198.463 378.079c-1.502-.04-2.86-.34-4.101-.875a9.79 9.79 0 0 1-5.29-5.487c-.484-1.24-.692-2.586-.653-4.049.039-1.437.339-2.769.888-3.984a9.75 9.75 0 0 1 2.26-3.148c.953-.888 2.063-1.554 3.33-2.024s2.652-.679 4.141-.64c1.502.039 2.874.339 4.115.875a9.835 9.835 0 0 1 3.226 2.22 9.372 9.372 0 0 1 2.051 3.266c.457 1.241.679 2.586.64 4.023-.039 1.463-.34 2.795-.876 4.01a9.501 9.501 0 0 1-2.246 3.148c-.954.888-2.077 1.554-3.344 2.025-1.28.47-2.665.679-4.154.64h.013Zm.092-3.24c1.28.039 2.416-.222 3.409-.783a6.108 6.108 0 0 0 2.351-2.365c.575-1.018.888-2.194.927-3.526.04-1.333-.209-2.521-.718-3.566a6.094 6.094 0 0 0-2.22-2.482c-.954-.614-2.077-.941-3.357-.98-1.28-.039-2.417.222-3.41.784a6.166 6.166 0 0 0-2.351 2.351c-.574 1.006-.888 2.182-.927 3.514-.039 1.332.196 2.534.718 3.579.523 1.058 1.254 1.881 2.208 2.495.953.614 2.077.94 3.357.979h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m247.236 366.494 2.886 1.842c-.587 1.254-1.476 2.364-2.664 3.304-1.189.941-2.6 1.594-4.219 1.96-1.463.326-2.848.378-4.18.156-1.319-.222-2.534-.666-3.618-1.345a9.226 9.226 0 0 1-2.756-2.665c-.758-1.097-1.293-2.351-1.607-3.788-.326-1.423-.366-2.795-.143-4.101a9.43 9.43 0 0 1 1.397-3.592 10.043 10.043 0 0 1 2.73-2.782c1.11-.771 2.39-1.32 3.827-1.633 1.62-.366 3.161-.366 4.611-.026 1.45.339 2.717.966 3.788 1.855l-1.816 2.899a6.787 6.787 0 0 0-2.69-1.502c-1.006-.287-2.064-.313-3.174-.065-1.202.274-2.195.797-3.005 1.593a6.504 6.504 0 0 0-1.698 2.874c-.326 1.123-.339 2.325-.052 3.618.287 1.28.823 2.377 1.594 3.266a6.328 6.328 0 0 0 2.769 1.881c1.071.365 2.207.418 3.396.143 1.11-.248 2.051-.718 2.847-1.41a6.757 6.757 0 0 0 1.79-2.508l-.013.026Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m274.378 362.835-12.644 5.015-7.131-17.973 12.643-5.016 1.137 2.861-9.248 3.67 1.816 4.572 8.777-3.475 1.137 2.861-8.778 3.488 1.907 4.806 9.248-3.67 1.136 2.861Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m288.08 355.885-3.148 1.842-9.77-16.693 6.766-3.958c1.202-.705 2.364-1.097 3.514-1.202 1.149-.091 2.194.092 3.148.562.953.47 1.724 1.202 2.312 2.221.679 1.162.94 2.364.77 3.592-.169 1.227-.718 2.351-1.658 3.357l9.378 3.657-3.853 2.26-8.726-3.606-2.547 1.49 3.801 6.491.013-.013Zm-4.584-16.144-3.619 2.116 2.848 4.885 3.618-2.116c.875-.51 1.424-1.123 1.672-1.855.248-.731.144-1.476-.3-2.234-.431-.744-1.032-1.188-1.79-1.332-.757-.144-1.567.039-2.442.549l.013-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m313.955 338.346-2.86 2.273-10.097-12.67-4.742 3.775-1.959-2.456 12.33-9.823 1.96 2.456-4.742 3.775 10.097 12.67h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m329.187 324.367-2.573 2.599-13.755-13.597 2.574-2.599 13.754 13.597Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m339.544 312.679-2.259 2.873-15.218-11.938 8.06-10.28 2.429 1.907-5.812 7.406 3.879 3.044 5.499-7.002 2.43 1.907-5.499 7.002 6.491 5.094v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m353.351 292.9-1.907 3.109-16.484-10.123 1.907-3.109 16.484 10.123Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m364.976 270.124-5.735 12.343-17.542-8.137 5.734-12.344 2.796 1.293-4.193 9.026 4.467 2.077 3.971-8.569 2.795 1.294-3.971 8.568 4.689 2.182 4.193-9.026 2.796 1.293Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m369.169 259.135-18.587-5.342 1.868-6.505c.575-1.999 1.476-3.631 2.704-4.898 1.228-1.267 2.664-2.129 4.297-2.56 1.633-.445 3.357-.405 5.16.117 1.815.523 3.304 1.411 4.467 2.652 1.149 1.241 1.92 2.73 2.286 4.454.365 1.724.261 3.592-.314 5.577l-1.868 6.505h-.013Zm-1.985-4.363.862-2.991c.379-1.306.431-2.495.183-3.592a5.535 5.535 0 0 0-1.581-2.821c-.81-.784-1.842-1.359-3.109-1.724-1.267-.366-2.442-.418-3.539-.183a5.495 5.495 0 0 0-2.822 1.554c-.797.797-1.371 1.842-1.75 3.148l-.862 2.991 12.605 3.618h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M368.032 218.946h-.078a.908.908 0 0 1-.823-.98c.444-4.898.666-9.836.666-14.682 0-19.619-3.5-38.859-10.397-57.159a.914.914 0 0 1 .522-1.162.899.899 0 0 1 1.163.522c6.975 18.509 10.515 37.958 10.515 57.799 0 4.912-.222 9.901-.666 14.852a.899.899 0 0 1-.902.823v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M42.804 218.943a.917.917 0 0 1-.901-.823 166.366 166.366 0 0 1-.667-14.852c0-19.841 3.54-39.29 10.515-57.799a.888.888 0 0 1 1.163-.522c.47.17.705.692.522 1.162-6.896 18.313-10.397 37.54-10.397 57.159 0 4.859.222 9.81.666 14.682a.908.908 0 0 1-.823.98h-.078v.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M281.498 282.739H126.074a3.089 3.089 0 0 1-3.083-3.083V125.003a3.088 3.088 0 0 1 3.083-3.083h154.653a3.088 3.088 0 0 1 3.083 3.083v155.424a2.302 2.302 0 0 1-2.299 2.298l-.013.014ZM126.074 123.736c-.706 0-1.28.574-1.28 1.28v154.653c0 .705.574 1.28 1.28 1.28h155.424a.496.496 0 0 0 .496-.496V125.029c0-.706-.575-1.28-1.28-1.28H126.061l.013-.013ZM203.4 258.9c-31.178 0-56.558-25.366-56.558-56.558s25.367-56.558 56.558-56.558c31.192 0 56.559 25.366 56.559 56.558S234.592 258.9 203.4 258.9Zm0-111.3c-30.186 0-54.755 24.556-54.755 54.755 0 30.2 24.556 54.756 54.755 54.756 30.2 0 54.756-24.556 54.756-54.756 0-30.199-24.556-54.755-54.756-54.755Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M203.401 224.144c-18.835 0-36.155-7.262-47.506-19.919l-.536-.601.536-.601c11.351-12.657 28.671-19.919 47.506-19.919s36.156 7.262 47.506 19.919l.536.601-.536.601c-11.35 12.657-28.671 19.919-47.506 19.919Zm-45.612-20.533c11.024 11.912 27.587 18.718 45.612 18.718 18.026 0 34.601-6.819 45.612-18.718-11.011-11.913-27.586-18.718-45.612-18.718-18.025 0-34.601 6.818-45.612 18.718Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M218.069 185.818a18.68 18.68 0 0 1 4.128 11.756c0 10.384-8.412 18.796-18.796 18.796-10.385 0-18.797-8.412-18.797-18.796 0-4.219 1.398-8.125 3.749-11.259l7.576-1.594 9.026-.535 13.114 1.645v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M203.399 217.259c-10.867 0-19.697-8.843-19.697-19.697 0-4.297 1.359-8.373 3.932-11.808l.209-.274 8.046-1.685 9.117-.549 13.571 1.711.236.288a19.783 19.783 0 0 1 4.323 12.317c0 10.867-8.843 19.697-19.697 19.697h-.04Zm-14.524-30.16a17.715 17.715 0 0 0-3.37 10.45c0 9.875 8.033 17.895 17.894 17.895 9.862 0 17.895-8.033 17.895-17.895 0-3.997-1.28-7.759-3.696-10.907l-12.67-1.593-8.882.522-7.184 1.515.013.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M249.939 204.16c-11.011-14.668-27.822-23.067-46.108-23.067-18.287 0-35.111 8.412-46.109 23.067l-1.437-1.084c11.351-15.126 28.684-23.799 47.559-23.799 18.874 0 36.207 8.673 47.558 23.799l-1.437 1.084h-.026Z"
          data-color="2"
        ></path>
        <path
          fill="#CDC4FF"
          d="M208.976 193.5a3.266 3.266 0 1 0 0-6.531 3.266 3.266 0 0 0 0 6.531Z"
          data-color="1"
        ></path>
        <path
          fill="#CDC4FF"
          d="M215.063 195.814a2.247 2.247 0 1 0 0-4.494 2.247 2.247 0 0 0 0 4.494Z"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="m270.447 296.4-20.259-20.259 8.033-8.02 12.226 12.226 30.708-30.722 8.033 8.02-38.741 38.755Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M270.448 296.847a.406.406 0 0 1-.314-.13l-20.246-20.246a.465.465 0 0 1 0-.64l8.02-8.034a.467.467 0 0 1 .64 0l11.913 11.913 30.395-30.395c.169-.17.47-.17.64 0l8.02 8.033c.17.17.17.457 0 .64l-38.742 38.742a.446.446 0 0 1-.313.13l-.013-.013Zm-19.619-20.703 19.619 19.619 38.101-38.102-7.393-7.393-30.395 30.396a.467.467 0 0 1-.64 0l-11.912-11.913-7.394 7.393h.014Z"
          data-color="2"
        ></path>
      </g>
    </svg>
  );
};

export default AccessibilityCertification;
