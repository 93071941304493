import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { Box, EmptyState, Button } from '@wix/design-system';
import { useBi } from '@components/Hooks/useBi';
import useMobileMediaQuery from '@components/Hooks/useMobileMediaQuery';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import Header from '@components/Header';
import { EXPLORE_URL } from '@utils/constants';
import s from './NotValidEuEmptyState.module.scss';

const NotValidEuEmptyState: FC = () => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin, isMobileUserAgent } = useProfileContext();
  const isMobileView = useMobileMediaQuery(isMobileUserAgent);

  function headerOnClick() {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'marketplace top button',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data: '',
      }),
    );
  }

  return (
    <Box display="block" className={s.root}>
      <div className={s.stickyHeader}>
        <Header
          onClick={headerOnClick}
          sessionId={sessionId}
          pageName="profile is not valid for EU"
        />
      </div>
      <Box
        align="center"
        marginTop="208px"
        height={`calc(100vh - 208px - ${isMobileView ? '54px' : '72px'})`}
      >
        <EmptyState
          theme="page-no-border"
          title={t('profile-page-not-valid-for-eu-title')}
          subtitle={t('profile-page-not-valid-for-eu-subtitle')}
          dataHook="profile-page-not-valid-for-eu"
        >
          <Box marginTop="3px">
            <Button
              skin="dark"
              dataHook="profile-page-not-valid-for-eu-cta"
              as="a"
              href={EXPLORE_URL}
            >
              {t('profile-page-not-valid-for-eu-cta')}
            </Button>
          </Box>
        </EmptyState>
      </Box>
    </Box>
  );
};

export default NotValidEuEmptyState;
