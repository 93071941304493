import React, { useMemo } from 'react';
import _ from 'lodash';
import { PublicCertification } from '@wix/ambassador-academy-certifications-v1-account-certification/types';
import { Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import LabeledCertification, {
  CertificationTypeToRenderDetails,
} from './LabeledCertification/LabeledCertification';
import useAccountCertifications from '@components/Hooks/useAccountCertifications';
import s from './Certifications.module.scss';

const getRows = (certificationList: PublicCertification[]) => {
  const rows = [];
  const rowSize = 3;
  while (certificationList.length > 0) {
    const row = certificationList.splice(0, rowSize);
    rows.push(row);
  }
  return rows;
};

const Certifications = ({ isMobileView }: { isMobileView?: boolean }) => {
  const { accountCertificationsState } = useAccountCertifications();
  const certificationList = accountCertificationsState?.data ?? [];

  const certificationRows = useMemo(() => {
    const certsToRender = certificationList
      .sort((cert1, cert2) => {
        return (
          new Date(cert2.createdDate!).getTime() -
          new Date(cert1.createdDate!).getTime()
        );
      })
      .filter((cert) => {
        const haveComponentForCert = Boolean(
          CertificationTypeToRenderDetails[cert.certificationType!].Component,
        );
        return haveComponentForCert;
      });

    return getRows(certsToRender);
  }, [certificationList]);

  if (_.isEmpty(certificationList)) {
    return null;
  }

  return (
    <Container isMobileView={isMobileView}>
      {certificationRows.map((row, i) => (
        <Box direction="horizontal" gap="18px" width="fit-content" key={i}>
          {row.map((cert) => (
            <LabeledCertification
              key={cert.certificationType}
              cert={cert}
              isMobile={isMobileView}
            />
          ))}
        </Box>
      ))}
    </Container>
  );
};

export default Certifications;

const Container = ({
  children,
  isMobileView,
}: {
  children: React.ReactNode;
  isMobileView?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box className={isMobileView ? s.mobileContainer : s.desktopContainer}>
      <Box
        maxHeight="270px"
        height="fit-content"
        padding={isMobileView ? '18px' : '24px'}
        margin="SP1 0 SP1 0"
        border="1px solid"
        borderColor="D60"
        borderRadius="8px"
        direction="vertical"
        gap="18px"
        boxSizing="border-box"
        minWidth="max-content"
      >
        <Text size="small" weight="normal">
          {t('profile-page-certifications-title')}
        </Text>
        {children}
      </Box>
    </Box>
  );
};
