import { useEffect, useState } from 'react';
import { useErrorMonitor } from '@wix/fe-essentials-standalone';
import {
  GetAccountCertificationsPublicResponse,
  PublicCertification,
} from '@wix/ambassador-academy-certifications-v1-account-certification/build/cjs/types.impl';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { HttpClient } from '@wix/http-client';

const STATUS = {
  loading: 'loading',
  error: 'error',
} as const;

type IStatus = keyof typeof STATUS;

interface ICertificationsState {
  status?: IStatus | null;
  data?: PublicCertification[] | null;
  error?: null | unknown;
}

const DEFAULT_STATE: ICertificationsState = {
  status: 'loading',
  data: null,
  error: null,
};

const useAccountCertifications = () => {
  const { host } = useProfileContext();
  const httpClient = new HttpClient({
    baseURL: host,
  });

  const [accountCertificationsState, setState] = useState(DEFAULT_STATE);
  const errorMonitor = useErrorMonitor();
  const {
    partnerProfile: { id },
  } = useProfileContext();

  const getAccountCertifications = async () => {
    const { data } =
      await httpClient.get<GetAccountCertificationsPublicResponse>(
        `_api/account-certifications/v1/account-certifications/${id}/public`,
      );

    return data;
  };

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const response = await getAccountCertifications();
        if (!ignore) {
          setState({
            status: null,
            data: response.certifications,
          });
        }
      } catch (e) {
        errorMonitor.captureException(e as Error);
        setState({
          status: STATUS.error,
          data: null,
          error: e,
        });
      }
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return {
    accountCertificationsState,
  };
};

export default useAccountCertifications;
