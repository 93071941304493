import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

const StudioDevCertification = ({ height = 60, width = 60 }: Props) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="0 0 406.801 406.801"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 406.801 406.801"
      height={height}
      width={width}
      data-type="color"
      role="img"
      aria-label="certification logo"
    >
      <g>
        <path
          fill="#1FFE73"
          d="M203.401 406.801c112.335 0 203.4-91.065 203.4-203.4C406.801 91.065 315.736 0 203.401 0 91.066 0 0 91.066 0 203.401c0 112.335 91.066 203.4 203.401 203.4Z"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="M89.828 114.381 67.4 93.051l7.811-8.216c2.365-2.482 4.912-4.206 7.615-5.147 2.717-.94 5.395-1.123 8.06-.549 2.651.575 5.08 1.907 7.275 3.998 2.195 2.09 3.645 4.44 4.35 7.066.705 2.625.666 5.316-.131 8.085-.797 2.769-2.377 5.395-4.741 7.877l-7.811 8.216Zm.64-9.666 2.69-2.822c1.973-2.063 2.913-4.27 2.835-6.622-.078-2.351-1.15-4.506-3.226-6.466-2.051-1.946-4.245-2.9-6.597-2.86-2.35.039-4.506 1.097-6.478 3.16l-2.678 2.822 13.454 12.801v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m130.985 78.815-18.561 12.88-17.647-25.42 18.561-12.879 3.527 5.082-12.448 8.634 3.475 5.015 11.768-8.163 3.527 5.08-11.769 8.164 3.579 5.16 12.448-8.634 3.527 5.081h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m150.696 69.762-6.819 2.887-23.394-23.694 7.459-3.161 16.04 17.32-1.293-23.564 7.249-3.07.758 33.295v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m186.968 60.243-22.166 4.401-6.022-30.355 22.166-4.402 1.202 6.074-14.864 2.952 1.188 5.982 14.055-2.782 1.202 6.074-14.055 2.782 1.228 6.152 14.864-2.952 1.202 6.074Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m212.047 59.577-21.291.405-.601-30.944 7.446-.143.47 24.582 13.846-.261.117 6.361h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M229.159 62.803c-2.417-.444-4.559-1.254-6.427-2.416-1.868-1.163-3.422-2.6-4.65-4.285-1.228-1.698-2.064-3.592-2.521-5.682-.444-2.09-.47-4.284-.039-6.583.418-2.299 1.215-4.337 2.377-6.113a15.077 15.077 0 0 1 4.376-4.389c1.75-1.15 3.71-1.933 5.865-2.35 2.155-.419 4.454-.419 6.857.025 2.417.444 4.546 1.254 6.427 2.417a16.27 16.27 0 0 1 4.65 4.284 15.05 15.05 0 0 1 2.521 5.656c.457 2.077.47 4.271.039 6.57-.418 2.299-1.215 4.35-2.39 6.14a15.01 15.01 0 0 1-4.376 4.414c-1.75 1.15-3.71 1.933-5.865 2.351-2.155.418-4.454.418-6.857-.026l.013-.013Zm1.188-6.426c1.764.326 3.37.209 4.846-.366 1.463-.562 2.717-1.49 3.723-2.796 1.019-1.293 1.698-2.86 2.038-4.702.339-1.841.261-3.54-.222-5.107-.497-1.554-1.32-2.86-2.495-3.919-1.176-1.044-2.639-1.737-4.389-2.063-1.737-.314-3.344-.196-4.82.365-1.489.562-2.73 1.49-3.749 2.77-1.019 1.28-1.685 2.847-2.024 4.689-.34 1.842-.262 3.553.222 5.12.483 1.567 1.319 2.887 2.508 3.945 1.188 1.058 2.638 1.737 4.375 2.063h-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m255.989 69.722-6.844-2.938 12.225-28.436 11.887 5.107c2.116.914 3.827 2.103 5.107 3.566 1.28 1.463 2.09 3.07 2.403 4.82.314 1.75.079 3.54-.705 5.368-.771 1.803-1.907 3.2-3.396 4.193-1.489.993-3.213 1.528-5.159 1.593-1.947.079-3.984-.34-6.1-1.253l-5.055-2.169-4.363 10.136v.013Zm14.825-20.572-5.055-2.168-2.978 6.922 5.055 2.169c1.163.496 2.234.587 3.187.26.954-.326 1.659-1.018 2.116-2.076.457-1.058.471-2.05.053-2.965-.418-.914-1.215-1.633-2.378-2.13v-.012Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m293.333 90.87-18.744-12.617 17.281-25.667 18.744 12.618-3.462 5.133-12.565-8.464-3.41 5.068 11.887 8.007-3.461 5.133-11.887-8.007-3.5 5.212 12.565 8.464-3.461 5.133.013-.012Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m301.483 98.396-5.238-5.29 21.983-21.774 9.105 9.196c1.619 1.645 2.756 3.383 3.396 5.224.64 1.842.783 3.632.418 5.369-.366 1.737-1.254 3.318-2.665 4.715-1.437 1.424-3.07 2.3-4.898 2.626-1.829.326-3.671.13-5.525-.588l-2.182 15.073-6.335-6.4 2.273-13.401-2.495-2.522-7.837 7.772Zm21.461-13.519-3.867-3.905-5.355 5.303 3.866 3.905c.889.902 1.842 1.385 2.848 1.437 1.006.052 1.92-.326 2.743-1.136.823-.81 1.202-1.724 1.162-2.73-.052-1.006-.522-1.973-1.41-2.874h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M281.447 283.717H126.023a3.089 3.089 0 0 1-3.083-3.083V125.981a3.089 3.089 0 0 1 3.083-3.083h154.653a3.089 3.089 0 0 1 3.083 3.083v155.424a2.302 2.302 0 0 1-2.299 2.299l-.013.013ZM126.023 124.714c-.705 0-1.28.575-1.28 1.28v154.653c0 .706.575 1.281 1.28 1.281h155.424a.496.496 0 0 0 .496-.497V126.007c0-.705-.574-1.28-1.28-1.28H126.01l.013-.013Zm77.327 135.165c-31.179 0-56.559-25.366-56.559-56.558s25.367-56.558 56.559-56.558c31.192 0 56.558 25.366 56.558 56.558s-25.366 56.558-56.558 56.558Zm0-111.301c-30.186 0-54.756 24.557-54.756 54.756s24.557 54.756 54.756 54.756 54.755-24.557 54.755-54.756-24.556-54.756-54.755-54.756Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m38.194 261.463-1.15-3.827 12.227-7.903-14.551.183-1.15-3.801 16.916-10.92 1.136 3.762-12.958 7.837 15.27-.143.967 3.187-12.984 8.386 15.308-.667 1.098 3.658-20.116.274-.013-.026Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m46.358 280.401-1.58-3.291 17.437-8.36 1.58 3.292-17.437 8.359Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m53.45 293.513-2.338-3.644 12.279.536 4.35-10.45 2.337 3.644-3.317 7.406 8.111.066 2.312 3.592-11.286-.34-4.623 11.364-2.338-3.644 3.565-8.308-9.052-.222Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m78.031 321.047 3.487-.065c-.104 1.123.053 2.273.497 3.435a8.254 8.254 0 0 0 1.985 3.03c.94.915 1.868 1.463 2.77 1.633.9.17 1.62-.026 2.194-.614.496-.522.653-1.175.457-1.972-.196-.797-.836-1.92-1.933-3.357-1.607-2.103-2.521-3.879-2.73-5.342-.222-1.463.196-2.743 1.254-3.828a5.136 5.136 0 0 1 2.873-1.554c1.098-.196 2.247-.065 3.423.366 1.175.444 2.299 1.175 3.37 2.194 1.24 1.202 2.129 2.508 2.638 3.919.51 1.411.732 2.834.653 4.258l-3.448.065a7.35 7.35 0 0 0-.3-3.2 6.524 6.524 0 0 0-1.725-2.73c-.849-.81-1.698-1.306-2.547-1.489-.862-.17-1.567.026-2.116.588-.444.457-.548 1.045-.326 1.75.222.719.875 1.777 1.946 3.187 1.606 2.103 2.508 3.919 2.717 5.46.209 1.542-.261 2.913-1.424 4.102-.849.888-1.842 1.41-2.965 1.593-1.123.183-2.299.026-3.514-.457-1.215-.483-2.39-1.28-3.526-2.377-1.333-1.293-2.3-2.678-2.887-4.18-.588-1.489-.862-2.978-.823-4.441v.026Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m104.17 347.409-2.991-2.103 9.326-13.258-4.951-3.488 1.803-2.56 12.892 9.078-1.802 2.56-4.951-3.487-9.326 13.258Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m142.167 346.559-5.16 10.515c-1.123 2.285-2.651 3.735-4.598 4.362-1.946.627-4.075.366-6.387-.77-2.299-1.124-3.801-2.652-4.493-4.572-.693-1.92-.484-4.023.627-6.296l5.159-10.515 3.279 1.607-5.16 10.515c-.653 1.332-.797 2.56-.457 3.696.34 1.137 1.163 2.025 2.469 2.652 1.306.64 2.521.757 3.644.339 1.123-.418 1.998-1.293 2.652-2.612l5.159-10.515 3.252 1.594h.014Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m145.183 368.439 5.538-18.535 6.479 1.933c1.985.601 3.605 1.516 4.872 2.757 1.254 1.24 2.09 2.677 2.521 4.323.418 1.646.365 3.357-.17 5.159-.549 1.816-1.437 3.292-2.691 4.428-1.254 1.137-2.743 1.881-4.48 2.234-1.737.353-3.592.222-5.578-.366l-6.478-1.933h-.013Zm4.388-1.933 2.979.888c1.293.392 2.494.457 3.592.222a5.586 5.586 0 0 0 2.834-1.554c.797-.797 1.385-1.829 1.763-3.083.379-1.267.458-2.442.222-3.54-.222-1.097-.731-2.037-1.515-2.847-.783-.797-1.828-1.398-3.122-1.776l-2.978-.889-3.762 12.566-.013.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m176.571 376.134-3.605-.601 3.161-19.084 3.605.601-3.161 19.084Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M198.411 378.559c-1.502-.039-2.86-.339-4.101-.875a9.757 9.757 0 0 1-3.213-2.221 9.554 9.554 0 0 1-2.064-3.265c-.484-1.241-.693-2.586-.653-4.049.039-1.437.339-2.769.888-3.984a9.714 9.714 0 0 1 2.26-3.148c.953-.888 2.063-1.554 3.33-2.025 1.267-.47 2.652-.679 4.141-.64 1.502.039 2.874.34 4.114.875a9.831 9.831 0 0 1 3.227 2.221 9.368 9.368 0 0 1 2.051 3.265c.47 1.241.679 2.587.64 4.024-.04 1.462-.34 2.795-.876 4.01a9.522 9.522 0 0 1-2.246 3.148c-.954.888-2.064 1.554-3.344 2.024-1.28.47-2.665.679-4.154.64Zm.092-3.239c1.28.039 2.416-.222 3.409-.784a6.112 6.112 0 0 0 2.351-2.364c.575-1.019.888-2.195.927-3.527.04-1.332-.209-2.521-.718-3.566-.523-1.045-1.254-1.868-2.221-2.482-.953-.613-2.076-.94-3.357-.979-1.28-.039-2.416.222-3.409.783a6.169 6.169 0 0 0-2.351 2.352c-.575 1.005-.888 2.181-.927 3.513-.039 1.333.196 2.534.718 3.579.523 1.058 1.254 1.881 2.208 2.495.953.614 2.076.941 3.357.98h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m247.197 366.978 2.887 1.842c-.588 1.254-1.476 2.364-2.665 3.305-1.188.94-2.599 1.593-4.219 1.959-1.463.326-2.847.379-4.18.157-1.319-.222-2.534-.666-3.618-1.346a9.234 9.234 0 0 1-2.756-2.664c-.757-1.098-1.293-2.365-1.62-3.788-.313-1.424-.365-2.796-.143-4.102a9.43 9.43 0 0 1 1.397-3.592 10.07 10.07 0 0 1 2.73-2.782c1.111-.771 2.391-1.319 3.828-1.633 1.619-.365 3.16-.365 4.61-.026 1.45.353 2.717.967 3.788 1.855l-1.815 2.9a6.767 6.767 0 0 0-2.691-1.502c-1.006-.288-2.064-.314-3.174-.066-1.202.275-2.195.797-3.017 1.594a6.482 6.482 0 0 0-1.698 2.873c-.327 1.124-.34 2.326-.053 3.619.288 1.28.823 2.377 1.594 3.265a6.474 6.474 0 0 0 2.769 1.894c1.071.366 2.207.418 3.396.144 1.11-.248 2.051-.719 2.848-1.411a6.75 6.75 0 0 0 1.789-2.508l.013.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m274.34 363.321-12.644 5.016-7.132-17.974 12.644-5.015 1.137 2.86-9.248 3.671 1.816 4.571 8.777-3.474 1.137 2.873-8.778 3.475 1.907 4.807 9.248-3.671 1.136 2.874v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m288.029 356.37-3.148 1.842-9.771-16.693 6.766-3.958c1.202-.705 2.378-1.097 3.514-1.189 1.15-.091 2.195.092 3.148.562.954.47 1.724 1.202 2.312 2.221.679 1.162.94 2.364.771 3.592-.17 1.227-.719 2.351-1.659 3.357l9.378 3.657-3.853 2.26-8.726-3.605-2.547 1.489 3.802 6.491.013-.026Zm-4.585-16.144-3.618 2.116 2.847 4.885 3.618-2.116c.875-.51 1.424-1.123 1.672-1.855.248-.731.144-1.476-.3-2.234-.431-.744-1.032-1.188-1.79-1.332-.757-.144-1.567.039-2.442.549l.013-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m313.904 338.826-2.86 2.273-10.097-12.67-4.742 3.775-1.959-2.456 12.33-9.822 1.96 2.455-4.742 3.775 10.097 12.67h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m329.135 324.853-2.573 2.6-13.754-13.598 2.573-2.599 13.754 13.597Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m339.493 313.159-2.26 2.874-15.217-11.939 8.059-10.28 2.429 1.908-5.812 7.406 3.879 3.043 5.499-7.001 2.43 1.907-5.499 7.001 6.492 5.094v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m353.285 293.398-1.907 3.109-16.484-10.123 1.907-3.109 16.484 10.123Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m364.924 270.604-5.734 12.344-17.543-8.138 5.735-12.343 2.795 1.293-4.193 9.026 4.467 2.077 3.971-8.569 2.795 1.293-3.971 8.569 4.69 2.181 4.193-9.026 2.795 1.293Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m369.115 259.621-18.587-5.342 1.868-6.505c.575-1.998 1.476-3.631 2.704-4.898 1.228-1.267 2.665-2.129 4.297-2.56 1.633-.444 3.357-.405 5.16.117 1.815.523 3.305 1.411 4.467 2.652 1.15 1.241 1.92 2.73 2.286 4.454.366 1.724.261 3.592-.314 5.578l-1.867 6.504h-.014Zm-1.985-4.362.862-2.991c.379-1.307.431-2.495.183-3.593a5.54 5.54 0 0 0-1.581-2.821c-.809-.784-1.841-1.358-3.108-1.724-1.267-.366-2.443-.418-3.54-.183a5.499 5.499 0 0 0-2.821 1.554c-.797.797-1.372 1.842-1.751 3.148l-.862 2.992 12.605 3.618h.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M40.805 219.074a.916.916 0 0 1-.901-.823 166.343 166.343 0 0 1-.667-14.851c0-28.018 7.184-55.683 20.795-80.005a.917.917 0 0 1 1.228-.352.917.917 0 0 1 .352 1.228c-13.453 24.06-20.56 51.424-20.56 79.129 0 4.859.223 9.809.667 14.681a.907.907 0 0 1-.823.98h-.078l-.013.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M366.021 219.074h-.079a.908.908 0 0 1-.823-.98c.445-4.898.667-9.836.667-14.682 0-27.704-7.106-55.069-20.56-79.129a.895.895 0 0 1 .353-1.228.895.895 0 0 1 1.228.353c13.597 24.321 20.794 51.986 20.794 80.004 0 4.912-.222 9.901-.666 14.852a.898.898 0 0 1-.901.823l-.013-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M296.115 245.421h-6.048v-10.815h5.199c.679 0 1.267-.249 1.75-.745.484-.496.719-1.097.719-1.815v-19.724c0-2.782.823-5.342 2.468-7.667l1.62-2.221-1.62-2.22c-1.645-2.325-2.468-4.886-2.468-7.668v-19.723c0-.68-.235-1.267-.719-1.751a2.386 2.386 0 0 0-1.75-.718h-5.199v-10.907h6.048c3.919 0 6.988 1.111 9.196 3.344 2.22 2.234 3.317 5.29 3.317 9.183v20.572c0 .431.144.875.431 1.359l3.828 5.446c.653.941.979 1.96.979 3.07 0 1.11-.326 2.129-.979 3.07l-3.828 5.446c-.287.366-.431.823-.431 1.359v20.572c0 3.919-1.11 6.989-3.317 9.196-2.221 2.221-5.277 3.318-9.196 3.318v.039Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M110.7 159.461h6.048v10.815h-5.199c-.679 0-1.267.248-1.75.745-.483.496-.718 1.097-.718 1.815v19.724c0 2.782-.823 5.342-2.469 7.667l-1.62 2.221 1.62 2.22c1.646 2.325 2.469 4.885 2.469 7.668v19.723c0 .679.235 1.267.718 1.751a2.386 2.386 0 0 0 1.75.718h5.199v10.907H110.7c-3.918 0-6.988-1.111-9.195-3.344-2.22-2.234-3.318-5.29-3.318-9.183v-20.572c0-.431-.144-.875-.431-1.359l-3.827-5.447c-.653-.94-.98-1.959-.98-3.069 0-1.11.327-2.129.98-3.07l3.827-5.447c.287-.365.431-.822.431-1.358v-20.573c0-3.918 1.11-6.988 3.318-9.195 2.22-2.221 5.277-3.318 9.195-3.318v-.039Z"
          data-color="2"
        ></path>
        <path
          fill="#1FFE73"
          d="M203.348 259.097c30.813 0 55.788-24.975 55.788-55.788 0-30.813-24.975-55.788-55.788-55.788-30.813 0-55.787 24.975-55.787 55.788 0 30.813 24.974 55.788 55.787 55.788Z"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="M203.349 259.997c-31.257 0-56.689-25.432-56.689-56.689s25.432-56.689 56.689-56.689 56.689 25.432 56.689 56.689-25.432 56.689-56.689 56.689Zm0-111.575c-30.265 0-54.886 24.621-54.886 54.886 0 30.264 24.621 54.886 54.886 54.886 30.265 0 54.886-24.622 54.886-54.886 0-30.265-24.621-54.886-54.886-54.886Z"
          data-color="2"
        ></path>
        <path
          fill="#1FFE73"
          d="M244.913 166.826c-8.216 5.421-23.747 9.052-41.563 9.052-17.817 0-33.308-3.631-41.538-9.039"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="M203.35 176.781c-17.294 0-33.4-3.514-42.034-9.182l.993-1.503c8.36 5.486 24.086 8.883 41.054 8.883 16.967 0 32.733-3.41 41.067-8.909l.992 1.502c-8.608 5.682-24.726 9.196-42.059 9.196l-.013.013Z"
          data-color="2"
        ></path>
        <path
          fill="#1FFE73"
          d="M161.784 240.196c8.216-5.42 23.747-9.051 41.563-9.051 17.817 0 33.308 3.631 41.537 9.038"
          data-color="1"
        ></path>
        <path
          fill="#000000"
          d="m162.281 240.94-.993-1.502c8.608-5.682 24.726-9.196 42.06-9.196 17.333 0 33.399 3.514 42.033 9.183l-.993 1.502c-8.359-5.486-24.086-8.882-41.04-8.882-16.955 0-32.734 3.409-41.067 8.908v-.013Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="M259.108 202.408H147.572v1.803h111.536v-1.803Z"
          data-color="2"
        ></path>
        <path
          fill="#000000"
          d="m203.348 260.115-.418-.222c-.261-.13-6.374-3.448-12.552-12.134-5.656-7.955-12.409-22.127-12.409-44.45 0-22.323 6.753-36.495 12.409-44.45 6.178-8.673 12.291-11.991 12.552-12.134l.392-.209.405.182c1.019.458 25.014 11.822 25.014 56.611 0 22.336-6.753 36.495-12.409 44.45-6.179 8.673-12.292 11.991-12.553 12.134l-.418.222h-.013Zm.026-111.562c-3.109 1.92-23.616 16.066-23.616 54.756 0 38.689 20.364 52.757 23.577 54.743 3.213-1.999 23.59-16.171 23.59-54.743 0-12.579-2.077-30.421-11.991-44.032-5.029-6.91-10.11-9.953-11.56-10.737v.013Z"
          data-color="2"
        ></path>
      </g>
    </svg>
  );
};

export default StudioDevCertification;
