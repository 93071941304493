import React from 'react';
import AccessibilityCertification from '@components/Images/Certifications/AccessibilityCertification';
import DeveloperCertification from '@components/Images/Certifications/StudioDevCertification';
import VeloCertification from '@components/Images/Certifications/VeloCertification';
import WebDesignerCertification from '@components/Images/Certifications/WebDesignerCertification';
import {
  CertificationType,
  PublicCertification,
} from '@wix/ambassador-academy-certifications-v1-account-certification/types';
import { Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';

export const CertificationTypeToRenderDetails: Record<
  CertificationType,
  { Component: React.FC | null; label: string }
> = {
  [CertificationType.ACCESSIBILITY]: {
    Component: AccessibilityCertification,
    label: 'profile-page-certifications-accessibility',
  },
  [CertificationType.STUDIO_DEVELOPER]: {
    Component: DeveloperCertification,
    label: 'profile-page-certifications-studio-developer',
  },
  [CertificationType.WEB_DESIGNER]: {
    Component: WebDesignerCertification,
    label: 'profile-page-certifications-web-designer',
  },
  [CertificationType.VELO]: {
    Component: VeloCertification,
    label: 'profile-page-certifications-velo-developer',
  },
  [CertificationType.UNKNOWN]: { Component: null, label: '' },
};

const LabeledCertification = ({
  cert,
  isMobile,
}: {
  cert: PublicCertification;
  isMobile?: boolean;
}) => {
  const { t } = useTranslation();
  const renderDetails =
    CertificationTypeToRenderDetails[cert.certificationType!];
  const CertificationImage = renderDetails.Component as React.FC<{
    width: number;
    height: number;
  }>;
  const labelKey = renderDetails.label;

  const imgDimension = isMobile ? 60 : 48;

  return (
    <Box
      key={cert.certificationType}
      direction="vertical"
      align="center"
      min-width="fit-content"
      gap="6px"
    >
      <CertificationImage width={imgDimension} height={imgDimension} />
      <Box>
        <Text size="tiny" weight="thin">
          {t(labelKey)}
        </Text>
      </Box>
    </Box>
  );
};

export default LabeledCertification;
